export const fovScenes = [
  {
    sceneId: '675a6490fef38c25a3605f6c',
    zoomOutFov: 100,
  },
  {
    sceneId: '675962455444e18f4b62d6b3',
    zoomOutFov: 100,
  },
  {
    sceneId: '67595f0a5444e18f4b62d361',

    zoomOutFov: 100,
  },
  {
    sceneId: '675952915444e18f4b62c003',

    zoomOutFov: 100,
  },
];
